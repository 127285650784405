import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Forma de copinya o llengua irregularment ondulada de 3 a 8 cm de diàmetre de color groguenc beix a groguenc olivaci, grisenc a vegades que amb l’edat torna coriaci. El peu si hi és, és molt curt i lateral. Presència de làmines de color ocre ataronjat que a vegades estan connectades unes amb les altres per una mena de venes perpendiculars a elles. Les espores són de color terrós argila en massa, ovalades, de 4-6 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      